<!--
 * @Author: gaojingran
 * @Date: 2021-04-19 14:55:14
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-19 11:40:26
 * @Description: 批准报价
-->
<style lang="less" scoped>
.quote-approve-modal {
  .item {
    display: flex;
    align-items: center;
    .label {
      flex: 0 0 180px;
      width: 180px;
      font-size: @font_size_2;
      color: @text_color_1;
    }
  }
}
</style>

<template>
  <a-modal
    :width="480"
    :title="$t('orderInfo.btn_quote_approve')"
    :maskClosable="false"
    :visible="visible"
    @cancel="handleCancel"
  >
    <a-spin :spinning="loading">
      <div class="quote-approve-modal">
        <!-- step 1 -->
        <template v-if="step === 1">
          <a-form-model ref="form" v-bind="formLayout" :colon="false" :model="form" :rules="rules">
            <a-form-model-item
              style="margin-bottom: 0px"
              labelAlign="left"
              prop="dueDate"
              :label="$t('orderInfo.quote_approve_confirm_date')"
            >
              <a-date-picker
                style="width: 100%"
                allowCler
                valueFormat="YYYY-MM-DD HH:mm:00"
                v-model="form.dueDate"
                :showTime="timePickerOption"
                :format="$t('dateFormat.a')"
              />
            </a-form-model-item>
          </a-form-model>
          <NeoTips
            class="mt-3"
            :label="$t('orderInfo.quote_approve_note_label')"
            :text="$t('orderInfo.quote_approve_note', { count: info.forecastWorkdays })"
          />
        </template>
        <!-- step 2 -->
        <template v-if="step === 2">
          <p class="mb-2">{{ $t('orderInfo.quote_approve_warning') }}</p>
          <div class="mb-2">
            <span class="c-1">{{ $t('orderInfo.quote_amount') }}:</span>
            <span class="pl-2 warning">
              <span v-if="currencyUnit()">{{ currencyUnit()[1] }}</span>
              <!-- <span class="bold">{{ info.quotePrice | countFormat }}</span> -->
              <span class="bold">{{
                reportInfo.quotePendingPrice !== null
                  ? reportInfo.quotePendingPrice
                  : reportInfo.quoteRealPrice !== null
                  ? reportInfo.quoteRealPrice
                  : info.quotePrice | countFormat
              }}</span>
            </span>
          </div>
          <div class="mb-2">
            <span class="c-1">{{ $t('orderInfo.quote_approve_due_date') }}:</span>
            <span class="pl-2 c-2">{{ this.form.dueDate | dateFormat }}</span>
          </div>
        </template>
      </div>
    </a-spin>
    <!-- footer -->
    <template v-slot:footer>
      <a-button type="primary" v-if="step === 1" :disabled="!form.dueDate" @click="handleNext">{{
        $t('next')
      }}</a-button>
      <a-button class="neo-btn-primary" v-if="step === 2" :disabled="loading" @click="step = 1">{{
        $t('prev')
      }}</a-button>
      <a-button type="primary" v-if="step === 2" :loading="loading" @click="handleConfirm">{{
        $t('confirm')
      }}</a-button>
    </template>
  </a-modal>
</template>

<script>
import NeoTips from '@/components/common/NeoTips'

export default {
  name: 'QuoteApproveModal',
  components: {
    NeoTips,
  },
  props: {
    // 订单info
    info: {
      type: Object,
      default: () => ({}),
    },
    // 报价单info
    reportInfo: {
      type: Object,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formLayout: {
        labelCol: { span: 10 },
        wrapperCol: { span: 12 },
      },
      loading: false,
      step: 1,
      form: {
        dueDate: undefined,
      },
      rules: {
        dueDate: [window.$g.validate.earlier_time],
      },
      timePickerOption: {
        format: 'HH:mm',
      },
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.form.dueDate = this.info.dueDate || undefined
      } else {
        this.step = 1
        this.form.dueData = undefined
        this.$refs.form.resetFields()
      }
    },
  },
  mounted() {
    console.log('123', this)
  },
  methods: {
    handleNext() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.step = 2
        }
      })
    },
    // 币种单位
    currencyUnit() {
      if (this.$is.Defined(this.info.currency)) {
        const unit = this.$store.getters['app/getDictLabel']('CURRENCY', this.info.currency) || ''
        return unit.split(';')
      } else {
        return false
      }
    },
    // 批准报价
    async handleConfirm() {
      try {
        this.loading = true
        await this.$http('orderProcessQuoteConfirm', {
          id: this.info.id,
          dueDate: this.form.dueDate,
        })
        this.loading = false
        this.handleCancel()
        this.$emit('closeDrawer')
        // 刷新订单info
        this.$bus.$emit('_refresh_order_info_')
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },
    handleCancel() {
      if (this.loading) return
      this.$emit('update:visible', false)
    },
  },
}
</script>
