<!--
 * @Author: gaojingran
 * @Date: 2021-03-22 17:15:55
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-04-02 13:41:49
 * @Description: 提示
-->

<style lang="less" scoped>
.tips {
  background: @background-color_4;
  border-radius: @border-radius_1;
  padding: 0 15px;
  display: inline-block;
  height: 24px;
  line-height: 22px;
  .label,
  .text {
    font-size: @font_size_1;
    display: inline-block;
    vertical-align: middle;
  }
  .label {
    color: @text_color_1;
  }
  .text {
    color: @text_color_2;
  }
}
</style>

<template>
  <span class="tips">
    <span class="label">{{ label }}:</span>
    <span class="text pl-2">{{ text }}</span>
  </span>
</template>

<script>
export default {
  name: 'NeoTips',
  props: {
    label: {
      type: String,
      default: '标题',
    },
    text: {
      type: String,
      default: '这是一个提示信息',
    },
  },
}
</script>
